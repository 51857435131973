<template>
  <v-container>
    <v-card>
        
      <v-card-title class="display-2 mb-5">{{mode=='edit'?'Edit User':'Create Admin'}}</v-card-title>
      <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-row>
          <v-col md="4">
            <v-text-field
              label="Full Name"
              required
              v-model="user.name"
              outlined
              dense
              hide-details="auto"
              :rules="requiredRule('Name')"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              label="Email"
              required
              v-model="user.email"
              outlined
              dense
              hide-details="auto"
              :rules="requiredRule('Email')"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              label="Phone Number"
              required
              v-model="user.phone"
              outlined
              dense
              hide-details="auto"
              :rules="requiredRule('Phone Number')"
            ></v-text-field>
          </v-col>
           
       
          <!-- <v-col md="4">
            <v-text-field
              label="Username"
              required
              v-model="user.username"
              outlined
              dense
              hide-details="auto"
              :rules="requiredRule('Username')"
            ></v-text-field>
          </v-col> -->
       
          <v-col md="4">
           
        <v-select
          :items="supervisorProfiles"
         
          label="User Role Profile"
          outlined
          dense
          hide-details="auto"
          v-model="user.profile"
          :rules="requiredRule('User Profile')"
        ></v-select>
        
          </v-col>

            <v-col md="4">
            <v-text-field
              label="password"
              type="password"
              required
              v-model="user.password"
              outlined
              dense
              hide-details="auto"
              :rules="requiredRule('Password')"
            ></v-text-field>
          </v-col>
      
        </v-row>

          </v-form>
      
      </v-card-text>
    </v-card>
    <v-btn color="primary" @click="save">Save</v-btn>
    
  </v-container>
</template>



<script>
export default {
  data() {
    return {
      user: {
          
      },
      
      valid: false,
      supervisorProfiles:[],
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  methods: {
       requiredRule(field) {
      return [(v) => !!v || `${field} is required`];
    },
      save(){
          if(this.valid){
              this.$Auth.createSupervisor(this.user).then(data=>{
                  this.$toastr.success("User Created Successfully");
                  this.$router.push({path:"/admin"});
              }).catch(error=>{
                  this.$toastr.error(error.response.data.message?error.response.data.message:'Something Went Wrong');
              })


          }else{
              
              this.$refs.form.validate()
          }
          
      },
      edit(){
           if(this.valid){   
              this.$Users.editClientUser(this.user).then(data=>{
                  this.$toastr.success("User Edited Successfully");
                  this.$router.push({path:"/admin"});
              }).catch(error=>{
                  this.$toastr.error(error.response.data.message);
                  console.log(error);
              })
          }else{
              this.$refs.form.validate()
          }
      },
      getUserById(){
          this.$Users.getUserById(this.$route.params.id).then(data=>{
              this.user = data.data.data;
          }).catch(error=>{
              console.log(error);
          })
      },

      getSupervisorProfiles(){
          this.$Auth.getSupervisorProfiles().then(data=>{
              
              this.supervisorProfiles = data.data.data;
          }).catch(error=>{
              console.log(error);
          })
      }
  },

  mounted() {
      this.getSupervisorProfiles();




  },
};
</script>

<style scoped>
</style>